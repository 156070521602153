<template>
	<div class="add-discount">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div style="padding: 15px;background: #fff;">
				<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;margin-bottom: 15px;color: #303133;">基本信息</div>

				<el-form-item label="活动名称" prop="activeName">
					<el-input :disabled="onlyLook" v-model="ruleForm.activeName" placeholder="最多输入20个字" maxlength="20" style="width:200px"></el-input>
				</el-form-item>

				<el-form-item label="活动时间" required>
					<el-date-picker v-model="activityTime" type="datetimerange" :picker-options="pickerOptions" range-separator="～"
					start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>

				<el-form-item label="活动限购">
					<div>
						<el-radio :disabled="onlyLook" v-model="ruleForm.radioLimit" label="0">不限购</el-radio>
					</div>
					<div>
						<el-radio :disabled="onlyLook" v-model="ruleForm.radioLimit" label="1">
							限购
							<el-input :disabled="onlyLook" v-model="ruleForm.limitNumber" style="width: 100px;margin: 0 5px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
							onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>件/人
						</el-radio>
					</div>
					<div style="color: #909399;">活动期间每个用户每种商品限购数量</div>
				</el-form-item>
			</div>

			<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 20px;">
				<div style="border-bottom: 1px solid #F0F2F5;padding-bottom: 15px;color: #303133;">活动信息</div>
				<el-button type="text" style="margin:10px 10px 10px 0;" @click="choseActive">选择活动商品</el-button>
				<el-input v-model="productSearchKey" :disabled="onlyLook" placeholder="搜索已选商品名称、编码" style="width:260px;"
				@keyup.enter.native="handleFilterSelectes">
					<el-button slot="append" icon="el-icon-search" @click="handleFilterSelectes"></el-button>
				</el-input>
				<div class="table-container" style="min-height: 30px;margin-bottom: 50px;">
					<el-table :data="ruleForm.ProductList" style="width: 100%;" ref="productChose">
						<el-table-column label="商品" width="300">
							<template slot-scope="scope">
								<div class="product-info">
									<img v-if="scope.row.thumbImgUrl" :src="imgUrl + scope.row.thumbImgUrl" />
									<svg-icon v-else icon-class="noImgIcon" />
									<div>
										<div>
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
										</div>
										<!-- <span v-if="!scope.row.IsOpen" style="color: #E51C23;">已下架</span> -->
										<span v-if="scope.row.totalStock<1" style="color: #E51C23;">已售罄</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="originalPrice" label="划线价"></el-table-column>
						<el-table-column prop="salePrice" label="销售价"></el-table-column>
						<el-table-column prop="totalStock" label="库存"></el-table-column>
						<el-table-column label="优惠" width="400px">
							<template slot-scope="scope">
								<div class="discount-container">
									<!-- <div class="discount-item">
										<span style="margin-right: 5px;">打</span>
										<el-form-item
											inline-message
											:prop="'ProductList.' +scope.$index + '.Discount'"
											:rules="rules.Discount"
											style="display:inline-block;margin:20px 0;height:50px;"
											label-width="0"
										>
											<el-input
												v-model="scope.row.Discount"
												:disabled="onlyLook"
												size="mini"
												class="input-with-select"
												:class="{ activepromotionMethod: scope.row.promotionMethod==2}"
												style="width: 100px;margin: 0 5px;"
												@change.native="changeDiscount(scope.row)"
											></el-input>
										</el-form-item>
										<span style="margin-left: 5px;">折</span>
									</div> -->
									<!-- <span style="margin:0 5px;">，</span> -->
									<!-- <div class="discount-item">
										<span style="margin-right: 5px;">立省</span>
										<el-form-item
											inline-message
											:prop="'ProductList.' +scope.$index + '.DiscountPrice'"
											:rules="rules.DiscountPrice"
											style="display:inline-block;margin:20px 0;height:50px;"
											label-width="0"
										>
											<el-input
												v-model="scope.row.DiscountPrice"
												:disabled="onlyLook"
												size="mini"
												class="input-with-select"
												:class="{ activepromotionMethod: scope.row.promotionMethod==3}"
												style="width: 120px;margin: 0 5px;"
												@input="changeDiscountPrice(scope.row)"
											></el-input>
										</el-form-item>
										<span style="margin-left: 5px;">元</span>
									</div> -->
									<!-- <span style="margin:0 5px;">，</span> -->
									<div class="discount-item">
										<span style="margin-right: 5px;">活动价</span>
										<el-form-item inline-message :prop="'ProductList.' +scope.$index + '.SpecifiedPrice'" :rules="rules.SpecifiedPrice"
										style="display:inline-block;margin:20px 0;height:50px;" label-width="0">
											<el-input v-model="scope.row.SpecifiedPrice" :disabled="onlyLook" size="mini" class="input-with-select"
											style="width: 120px;margin: 0 5px;" :class="{ activepromotionMethod: scope.row.promotionMethod==4}"
											@change.native="changeSpecifiedPrice(scope.row)"></el-input>
										</el-form-item>
										<span style="margin-left: 5px;">元</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="DiscountPrice" label="立省" width="200px">
							<!-- <template slot-scope="scope">
								<span v-if="scope.row.promotionMethod==1">--</span>
								<div
									v-if="scope.row.promotionMethod!=1"
									@click="handleShowTooltip(scope.row)"
									style="color:#409EFF;cursor:pointer;"
								>
									<span v-if="scope.row.promotionMethod==2">打折</span>
									<span v-if="scope.row.promotionMethod==3">减金额</span>
									<span v-if="scope.row.promotionMethod==4">活动价</span>
								</div>
							</template> -->
						</el-table-column>
						<el-table-column label="操作" width="120" v-if="!onlyLook">
							<template slot-scope="scope">
								<span style="color:#C0C4CC;" v-if="onlyLook">删除</span>
								<span style="color:#F56C6C;cursor:pointer;" v-else @click="deleteChose(scope.row)">删除</span>
							</template>
						</el-table-column>
					</el-table>
					<div style="display:flex;justify-content:space-between;align-items:center;">
						<div style="display:flex;font-size: 14px;color: #606266;align-items:center;margin-left: 13px;padding-top: 20px;"
						v-if="!onlyLook&&selectedList.length">
							批量设置：
							<div style="display: flex;" v-show="!isShowEdit">
								<!-- <div style="color: #409EFF;cursor:pointer;" @click="commonDiscountClick">折扣</div> -->
								<!-- <div
									style="color: #409EFF;margin:0 10px;cursor:pointer;"
									@click="commonDiscountPirceClick"
								>立省</div> -->
								<div style="color: #409EFF;cursor:pointer;" @click="commonSpecifiedPriceClick">活动价</div>
							</div>
							<!-- 批量折扣 -->
							<!-- <div v-show="isShowEdit&&currentType==0" style="display: flex;align-items: center;">
								<el-input
									v-model="commonDiscount"
									size="mini"
									style="margin-left: 5px;"
									@input="changeCommonSpecifiedPrice('commonDiscount')"
								></el-input>
								<span
									style="margin-left: 10px;white-space: nowrap;cursor:pointer;"
									@click="editDiscountOk"
								>确定</span>
								<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editCancel">取消</span>
							</div> -->
							<!-- 批量减金额 -->
							<!-- <div v-show="isShowEdit&&currentType==1" style="display: flex;align-items: center;">
								<el-input
									v-model="commonDiscountPrice"
									size="mini"
									style="margin-left: 5px;"
									@input="changeCommonSpecifiedPrice('commonDiscountPrice')"
								></el-input>
								<span
									style="margin-left: 10px;white-space: nowrap;cursor:pointer;"
									@click="editDiscountPriceOk"
								>确定</span>
								<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editCancel">取消</span>
							</div> -->
							<!-- 批量活动价 -->
							<div v-show="isShowEdit&&currentType==2" style="display: flex;align-items: center;">
								<el-input v-model="commonSpecifiedPrice" size="mini" style="margin-left: 5px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
								<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editSpecifiedPriceOk">确定</span>
								<span style="margin-left: 10px;white-space: nowrap;cursor:pointer;" @click="editCancel">取消</span>
							</div>
						</div>
						<div v-else></div>

					</div>
				</div>
			</div>
		</el-form>
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1400px" class="dialog">
			<select-produce api="activitycouponChoseproductlist" :params="['goodsName', 'barCode']" :selectedData="selectedList"
			@getSelectList="getSelectList" :visible.sync="addProductDialogShow" v-if="addProductDialogShow"></select-produce>
		</el-dialog>

		<!-- 冲突商品列表 -->
		<!-- 	<el-dialog :visible.sync="conflictVisible" width="1000px;" title="以下商品与其它活动冲突,请重新设置" center>
			<el-table :data="conflictList" style="max-height:450px;overflow: auto;">
				<el-table-column prop="ProductName" label="商品"></el-table-column>
				<el-table-column prop="Remark" label="冲突信息"></el-table-column>
			</el-table>
		</el-dialog>-->

		<div style="background: #fff;padding: 15px;margin-top: 10px;margin-bottom: 20px;display: flex;justify-content: center;">
			<el-button style="width: 150px;" @click="handleCancel">取消</el-button>
			<el-button style="width: 150px;margin-left: 40px;" type="primary" v-if="!onlyLook" @click="saveBtn" :loading="loading">保存</el-button>
		</div>

		<el-dialog title="优惠明细" width="700px" :visible.sync="tooltipTableVisible">
			<div style="margin-bottom:10px;font-size: 16px;">
				商品：
				{{tooltipTableName}}
			</div>
			<el-table :data="tooltipTableData" max-height="600" v-loading="tooltipLoading">
				<el-table-column label="规格名">
					<template>
						<span>规格</span>
					</template>
				</el-table-column>
				<el-table-column label="规格值">
					<template slot-scope="scope">
						<span>{{scope.row.SpecValue}}</span>
						<br />
						<span>{{scope.row.SpecValue2}}</span>
					</template>
				</el-table-column>
				<el-table-column label="原价" prop="originalPrice"></el-table-column>
				<el-table-column label="活动价" prop="ActivePrice"></el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		timelimitdiscountdetail,
		// eslint-disable-next-line 
		discountchoseproductlist,
		timelimitdiscountedit
	} from '@/api/goods'
	import apiList from '@/api/other'
	import selectProduce from './coupon/selectMultiPro.vue';
	import config from '../../config/index.js'
	export default {
		components: {
			selectProduce
		},
		data() {
			var checkDiscount = (rule, value, callback) => {
				let index1 = rule.fullField.split(".")[1];
				let index = (this.page.current - 1) * (this.page.size) + Number(index1)
				if (this.selectedFilterList[index].promotionMethod == 2) {
					let value1 = this.selectedFilterList[index].Discount

					if (Number(value1) > 9.9 || Number(value1) < 0.1) {
						return callback(new Error('0.1≦折扣≦9.9，最多1位小数'));
					} else if (Number(value1) == 0 || Number(value1) < 0 || value1 == '' || value1 == null) {
						return callback(new Error('0.1≦折扣≦9.9，最多1位小数'));
					} else {
						return callback()
					}
				} else {
					return callback()
				}
			};
			var checkDiscountPrice = (rule, value, callback) => {

				let index1 = rule.fullField.split(".")[1];
				let index = (this.page.current - 1) * (this.page.size) + Number(index1)
				if (this.selectedFilterList[index].promotionMethod == 3) {
					let priceMin = this.selectedFilterList[index].originalPrice
					let value1 = this.selectedFilterList[index].DiscountPrice

					if (value1 > priceMin || value1 == priceMin) {
						return callback(new Error('减免金额不可超过商品划线价'));
					} else if (value1 == 0 || value1 == '0' || Number(value1) < 0 || value1 == '') {
						return callback(new Error('减免金额需大于0，且不能为小数！'));
					} else {
						return callback()
					}
				} else {
					return callback()
				}

			};
			var checkSpecifiedPrice = (rule, value, callback) => {
				let index1 = rule.fullField.split(".")[1];
				let index = (this.page.current - 1) * (this.page.size) + Number(index1)
				if (this.selectedFilterList[index].promotionMethod == 4) {
					let priceMin = this.selectedFilterList[index].originalPrice
					let value1 = this.selectedFilterList[index].SpecifiedPrice

					if ((value1 > priceMin || value1 == priceMin) && value1 != 0) {
						return callback(new Error('活动价不可超过商品划线价'));
					} else if (value1 == 0 || value1 == '0' || Number(value1) < 0 || value1 == '') {
						return callback(new Error('活动价需大于0，最多2位小数'));
					} else {
						return callback()
					}
				} else {
					return callback()
				}

			};
			var checkActivityName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			return {
				isSetSuperimposed: false,
				tooltipLoading: false,
				currentIndex: 0,
				loading: false,
				ruleForm: {
					activeName: '',
					activeTag: '',
					radioLimit: '0',
					limitNumber: '',
					IsSuperpositionCoupon: 'true',
					StartTime: '',
					EndTime: '',
					Discount: '',
					DiscountPrice: '',
					SpecifiedPrice: '',
				},
				rules: {
					Discount: [{
						required: true,
						trigger: 'change',
						validator: checkDiscount
					}],
					DiscountPrice: [{
						required: true,
						trigger: 'change',
						validator: checkDiscountPrice
					}],
					SpecifiedPrice: [{
						required: true,
						trigger: 'change',
						validator: checkSpecifiedPrice
					}],
					activeName: [{
							required: true,
							trigger: 'blur',
							validator: checkActivityName
						},
						{
							min: 1,
							max: 20,
							message: '最多输入20个字',
							trigger: 'blur'
						}
					],
					IsSuperpositionCoupon: [{
						required: true,
						message: '请配置优惠券叠加方案',
						trigger: 'change'
					}],
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'change'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'change'
					}],
				},
				productSearchKey: '',
				installationLength: 0,
				discountSetType: false,
				selectedFilterList: [],
				productData: [],
				selectedList: [],
				paginationList: [],
				choseData: [],
				page: {
					total: 0,
					size: 10,
					current: 1
				},
				commonDiscount: '',
				commonDiscountPrice: '',
				commonSpecifiedPrice: '',
				isShowEdit: false, //是否显示批量编辑输入框
				currentType: 0, //0为批量打折,1为批量折后价
				checkedAll: false, //当前页全选
				checked: true, //是否 仅看可选商品
				addProductDialogShow: false,
				currentPage1: 1,
				pageSize1: 5,
				Total1: 0,
				roleList: [],
				searchKey: '',
				saleType: '', //销售类型
				goodsType: '', //商品分组
				brandType: '', //品牌分组
				activityId: 0, //活动id
				onlyLook: 0,
				isCopy: 0,
				conflictVisible: false, //是否显示冲突tank
				conflictList: [], //冲突信息列表
				activeState: 1, //判断活动是否开始，false：未开始
				timeData: {
					StartTime: '',
					EndTime: ''
				},
				tooltipTableName: '',
				tooltipTableData: [],
				tooltipTableVisible: false,
				imgUrl: config.IMG_BASE,
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				activityTime: ''
			};
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			},
		},
		beforeMount() {
			this.isCopy = this.$route.query.copy ? true : false;
			this.onlyLook = this.$route.query.onlylook ? true : false;
			this.activeState = this.$route.query.activeState ? this.$route.query.activeState : 1;
			if (this.$route.query.id) {
				this.activityId = this.$route.query.id;
				this.getData();

				if (this.isCopy && this.$route.query.copy == 1) {
					this.activityId = 0;
				}
			}
		},
		methods: {

			// changeCommonDiscount (value) {
			// 	value = (value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
			// 	if (Number(value) > 9.9) {
			// 		value = null
			// 	} else if (Number(value) < 0.1) {
			// 		value = null
			// 	}
			// 	this.commonDiscount = value
			// },

			// changeCommonDiscountPrice (value) {
			// 	value = (value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
			// 	this.commonDiscountPrice = value
			// },
			changeCommonSpecifiedPrice(key) {
				console.log("---key----", key);
				// if(val<0){
				// 	this.batchKillPrice = Math.abs(val);
				// }
				var tempNum = this[key];
				console.log("---tempNum----", tempNum);
				tempNum = tempNum.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
				if (tempNum.indexOf('.') < 0 && tempNum != '') {
					// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					tempNum = parseInt(tempNum);
				}
				this[key] = tempNum;
			},
			//设置打折
			changeDiscount(value) {
				let Id = value.id
				value.Discount = ((value.Discount).match(/^\d*(\.?\d{0,1})/g)[0]) || null
				if (Number(value.Discount) > 9.9) {
					value.Discount = null
				} else if (Number(value.Discount) < 0.1) {
					value.Discount = null
				}

				if (Number(value.Discount) > 0) {
					this.selectedList.forEach(t => {
						if (t.id == Id) {
							t.promotionMethod = 2
							t.Discount = Number(value.Discount)

							let result = this.computedThreeDiscounts(t.promotionMethod, t, Number(value.Discount))
							t.DiscountPrice = result.DiscountPrice
							t.SpecifiedPrice = result.SpecifiedPrice
						}
					})
				} else {
					this.selectedList.forEach(t => {
						if (t.id == Id) {
							value.Discount = null
							t.DiscountPrice = 0
							t.SpecifiedPrice = t.salePrice
							t.promotionMethod = 2
						}
					})
				}

				this.$forceUpdate()
				this.handleSingleSetting()
				this.$refs['ruleForm'].validateField('Discount')
			},
			changeSuperimposedRadio() {
				this.isSetSuperimposed = false
			},
			//设置减金额
			changeDiscountPrice(value) {
				let Id = value.id
				console.log(value.DiscountPrice);
				// value.DiscountPrice = ((value.DiscountPrice).match(/^\d*(\.?\d{0,2})/g)[0]) || null

				var tempNum = value.DiscountPrice;
				tempNum = tempNum.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
				if (tempNum.indexOf('.') < 0 && tempNum != '') {
					// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					tempNum = parseInt(tempNum);
				}
				value.DiscountPrice = tempNum;

				this.selectedList.forEach(t => {
					if (t.id == Id) {
						t.promotionMethod = 3
						t.DiscountPrice = Number(value.DiscountPrice)

						let result = this.computedThreeDiscounts(t.promotionMethod, t, Number(value.DiscountPrice))
						t.Discount = result.Discount
						t.SpecifiedPrice = result.SpecifiedPrice
					}
				})
				this.$forceUpdate()
				this.handleSingleSetting()
				this.$refs['ruleForm'].validateField('DiscountPrice')
			},
			//设置促销价
			changeSpecifiedPrice(value) {

				let Id = value.id

				value.SpecifiedPrice = ((value.SpecifiedPrice).match(/^\d*(\.?\d{0,2})/g)[0]) || null

				this.selectedList.forEach(t => {
					if (t.id == Id) {
						t.promotionMethod = 4
						t.SpecifiedPrice = Number(value.SpecifiedPrice)

						let result = this.computedThreeDiscounts(t.promotionMethod, t, Number(value.SpecifiedPrice))
						t.Discount = result.Discount
						t.DiscountPrice = result.DiscountPrice
					}
				})

				this.$forceUpdate()
				this.handleSingleSetting()
				this.$refs['ruleForm'].validateField('SpecifiedPrice')
			},

			handleSingleSetting() {
				// let currentPage = this.page.current
				let currentLength = this.selectedFilterList.length
				this.selectedFilterList = []
				this.selectedList.filter(t => {
					if ((t.goodsName.indexOf(this.productSearchKey) != -1)) {
						this.selectedFilterList.push(t)
					}
				})

				if (this.selectedFilterList.length < currentLength) {
					if ((this.selectedFilterList.length) % this.page.size == 0 && this.page.current > 1) {
						this.page.current -= 1;
					}
				}

				this.page.total = this.selectedFilterList.length;
				this.installationLength = 0
				this.computedInstallationLength()

				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.ruleForm.ProductList = pagination
				this.$forceUpdate()
			},
			//批量设置打折
			editDiscountOk() {
				this.isShowEdit = false

				if (Number(this.commonDiscount) == 0 || Number(this.commonDiscount) < 0 || this.commonDiscount == null) {
					this.selectedFilterList.forEach(t => {
						t.promotionMethod = 2

						t.Discount = null;

						t.DiscountPrice = 0
						t.SpecifiedPrice = t.salePrice
					})
				} else {
					this.selectedFilterList.forEach(t => {
						t.promotionMethod = 2

						t.Discount = Number(this.commonDiscount);
						let result = this.computedThreeDiscounts(t.promotionMethod, t, Number(this.commonDiscount))

						t.DiscountPrice = result.DiscountPrice
						t.SpecifiedPrice = result.SpecifiedPrice
					})
				}
				this.handleFilterSelectes()
				this.$refs['ruleForm'].validateField('Discount')
			},
			//批量设置优惠金额
			editDiscountPriceOk() {
				this.isShowEdit = false
				this.selectedFilterList.forEach(t => {
					t.promotionMethod = 3

					t.DiscountPrice = Number(this.commonDiscountPrice);

					let result = this.computedThreeDiscounts(t.promotionMethod, t, Number(this.commonDiscountPrice))
					t.Discount = result.Discount
					t.SpecifiedPrice = result.SpecifiedPrice

					this.handleFilterSelectes();
					this.$refs['ruleForm'].validateField('DiscountPrice')
				})
			},

			//批量设置活动价
			editSpecifiedPriceOk() {
				this.isShowEdit = false
				this.selectedFilterList.forEach(t => {
					t.promotionMethod = 4
					t.SpecifiedPrice = Number(this.commonSpecifiedPrice);

					let result = this.computedThreeDiscounts(t.promotionMethod, t, this.commonSpecifiedPrice)
					t.Discount = result.Discount
					t.DiscountPrice = result.DiscountPrice
				})
				this.handleFilterSelectes();
				this.$refs['ruleForm'].validateField('SpecifiedPrice')
			},

			async getData() {
				this.loading = true
				const res = await timelimitdiscountdetail({
					id: +this.activityId
				})

				if (res.success) {
					const obj = res.data;
					this.ruleForm = {
						activeName: obj.activityName,
						activeTag: obj.activityLab,
						radioLimit: obj.isRest == 1 ? '0' : '1',
						limitNumber: obj.restNum,
						// IsSuperpositionCoupon: obj.isOver == 0 ? true : false,
						IsSuperpositionCoupon: true,
						// StartTime: this.isCopy ? '' : obj.begTime,
						// EndTime: this.isCopy ? '' : obj.endTime,
					}
					if (this.isCopy && this.$route.query.copy == 1) {
						this.activityTime = "";
					} else {
						this.activityTime = [obj.begTime, obj.endTime]
					}
					if (res.data.list && res.data.list.length) {
						this.selectedList = res.data.list.map(t => {
							//type 1,2,3本部对应折扣，减金额，活动价
							let promotionMethod = 1
							t.type = t.type || 1;
							if (t.type == 1) {
								promotionMethod = 2
							} else if (t.type == 2) {
								promotionMethod = 3
							} else if (t.type == 3) {
								promotionMethod = 4
							}
							t.originalPrice = t.originalPrice || 0;
							return {
								...t,
								Discount: t.discount / 10,
								DiscountPrice: t.subMoney,
								SpecifiedPrice: t.activityMoney,
								promotionMethod: promotionMethod,
								thumbImgUrl: t.goodsUrl,
								id: t.goodsId
							}

						})
					}
					this.selectedFilterList = this.selectedList

					this.paginationPro()
					this.$nextTick(() => {
						this.$refs.ruleForm.validate();
					})
				} else {
					this.$message({
						type: 'error',
						showClose: true,
						message: res.alertMsg
					})
				}
				this.loading = false

			},
			computedThreeDiscounts(promotionMethod, item, settings) {
				let discount = 0
				let discountMin = 0

				let discountPrice = 0
				let discountPriceMin = 0
				let specifiedPrice = 0
				let specifiedPriceMin = 0
				if (promotionMethod == 1) {
					discount = null
					discountPrice = null
					specifiedPrice = null
				} else if (promotionMethod == 2) {
					// 设置的值是打折
					discount = settings

					specifiedPriceMin = (settings * item.originalPrice / 10).toFixed(2)

					discountPriceMin = (item.originalPrice - settings * item.originalPrice / 10).toFixed(2)

					discountPrice = discountPriceMin
					specifiedPrice = specifiedPriceMin

				} else if (promotionMethod == 3) {
					// 设置的值是减金额
					discountPrice = settings

					specifiedPriceMin = (item.originalPrice - settings).toFixed(2)
					discountMin = item.originalPrice == 0 ? 0 : ((item.originalPrice - settings) / item.originalPrice * 10).toFixed(1)

					specifiedPrice = specifiedPriceMin
					discount = discountMin

				} else if (promotionMethod == 4) {
					// 设置的值是活动价
					specifiedPrice = settings

					discountMin = item.originalPrice == 0 ? 0 : (settings / item.originalPrice * 10).toFixed(1)
					discountPriceMin = (item.originalPrice - settings).toFixed(2)

					discountPrice = discountPriceMin
					discount = discountMin

				}
				let result = {
					Discount: discount,
					DiscountPrice: discountPrice,
					SpecifiedPrice: specifiedPrice,
				}
				return result
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			commonDiscountClick() {
				this.currentType = 0
				this.commonDiscount = ''
				this.isShowEdit = true
			},
			commonDiscountPirceClick() {
				this.commonDiscountPrice = ''
				this.currentType = 1
				this.isShowEdit = true
			},
			commonSpecifiedPriceClick() {
				this.commonSpecifiedPrice = ''
				this.currentType = 2
				this.isShowEdit = true
			},
			editCancel() {
				this.isShowEdit = false
			},
			choseActive() {
				if (this.onlyLook) {
					return;
				}
				if (this.activityTime) {
					this.ruleForm.StartTime = this.activityTime[0];
					this.ruleForm.EndTime = this.activityTime[1];
				} else {
					this.ruleForm.StartTime = '';
					this.ruleForm.EndTime = '';
				}
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					this.addProductDialogShow = true;
					this.timeData = {
						StartTime: this.ruleForm.StartTime,
						EndTime: this.ruleForm.EndTime
					}
				}
			},

			getSelectList(data) {
				if (data.length > 10) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多选择十个商品！'
					})
					return;
				}

				this.productSearchKey = ''
				this.addProductDialogShow = false
				if (this.selectedList.length) {
					let temp = this.selectedList.map(item => item.id)
					let resArr = data.filter(item => !temp.includes(item.id))
					resArr = resArr.map(t => {
						return {
							...t,
							Discount: '',
							DiscountPrice: '',
							SpecifiedPrice: '',
							promotionMethod: 1,
							originalPrice: t.originalPrice,
						}
					})
					resArr.forEach(t => {
						this.selectedList.push(t)
					})
				} else {
					var list = data.map(t => {
						return {
							...t,
							Discount: '',
							DiscountPrice: '',
							SpecifiedPrice: '',
							promotionMethod: 1,
							originalPrice: t.originalPrice,
						}
					})
					this.selectedList = list;
				}
				console.log("-------", this.selectedList)
				this.handleFilterSelectes()
			},
			deleteChose(val) {
				this.selectedList = this.selectedList.filter(t => {
					if (t.id != val.id) {
						return t
					}
				})
				this.selectedFilterList = this.selectedFilterList.filter(t => {
					if (t.id != val.id) {
						return t
					}
				})
				if ((this.selectedFilterList.length) % this.page.size == 0 && this.page.current > 1) {
					this.page.current -= 1;
				}

				this.paginationPro()
				this.$nextTick(() => {
					this.$refs.ruleForm.validate();
				})
			},
			// 商品弹窗切换显示条数
			productSizeChange(val) {
				this.page.size = val;
				this.paginationPro()
				this.$nextTick(() => {
					this.$refs.ruleForm.validate();
				})
			},
			productCurrentChange(val) {
				this.page.current = val;
				this.paginationPro()
				this.$nextTick(() => {
					this.$refs.ruleForm.validate();
				})
			},
			changeDiscountSetType() {
				this.handleFilterSelectes()
				this.$nextTick(() => {
					this.$refs.ruleForm.validate();
				})
			},
			handleFilterSelectes() {
				this.page.current = 1
				this.selectedFilterList = [];
				var keys = this.productSearchKey || '';
				this.selectedList.filter(t => {
					if ((t.goodsName.trim().indexOf(keys) != -1)) {
						this.selectedFilterList.push(t)
					}
				})
				this.paginationPro()
			},
			//商品分页
			paginationPro() {
				this.page.total = this.selectedFilterList.length;
				this.installationLength = 0
				this.computedInstallationLength()
				let start = (this.page.current - 1) * this.page.size
				let end = start + this.page.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.ruleForm.ProductList = pagination
				this.$forceUpdate()
			},
			// 计算商品折扣设置错误的数量
			computedInstallationLength() {
				this.installationLength = 0
				this.selectedList.forEach(t => {
					console.log("---tttt--", t)
					if (t.promotionMethod == 1) {
						this.installationLength += 1
					} else if (t.promotionMethod == 2) {
						if (t.originalPrice != 0 && (Number(t.Discount) < 0 || Number(t.Discount) == 0 || t.Discount == '' || t.Discount ==
								null)) {
							this.installationLength += 1
						}
					} else if (t.promotionMethod == 3) {
						if (t.originalPrice != 0 && (t.originalPrice == t.DiscountPrice || t.DiscountPrice > t.originalPrice || t.DiscountPrice ==
								0 || t.DiscountPrice < 0)) {
							this.installationLength += 1
						}
					} else if (t.promotionMethod == 4) {
						if (t.originalPrice != 0 && (t.originalPrice == t.SpecifiedPrice || t.SpecifiedPrice > t.originalPrice || t.SpecifiedPrice ==
								0 || t.SpecifiedPrice < 0)) {
							this.installationLength += 1
						}
					}
				})
			},
			handleCancel() {
				this.$router.back();
			},

			saveBtn() {
				if (this.ruleForm.IsSuperpositionCoupon === '') {
					this.isSetSuperimposed = true
				}
				if (this.activityTime) {
					this.ruleForm.StartTime = this.activityTime[0];
					this.ruleForm.EndTime = this.activityTime[1];
				} else {
					this.ruleForm.StartTime = '';
					this.ruleForm.EndTime = '';
				}
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择活动时间'
					})
					return;
				}
				this.$refs['ruleForm'].validate((valid) => {
					if (this.installationLength) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '商品折扣信息配置错误'
						})
						// this.discountSetType = true
						this.page.current = 1
						this.handleFilterSelectes()
						this.$nextTick(() => {
							this.$refs.ruleForm.validate();
						})
						return
					}
					if (valid && !this.isSetSuperimposed) {
						if (!this.selectedList.length) {
							this.$message({
								showClose: true,
								type: 'error',
								message: '请选择活动商品'
							});
							return;
						}
						this.sureSave();
					} else {
						console.log('error submit!!');
						this.$message.error('请完善活动信息');
						return false;
					}
				});

			},
			async sureSave() {
				this.loading = true
				try {
					let TimeLimitDiscountProductList = this.selectedList.map(t => {
						if (+this.activityId) {
							return {
								activityId: +this.activityId,
								goodsId: t.id,
								discount: t.Discount * 10,
								subMoney: +t.DiscountPrice,
								activityMoney: +t.SpecifiedPrice,
							}
						} else {
							return {
								goodsId: t.id,
								discount: t.Discount * 10,
								subMoney: +t.DiscountPrice,
								activityMoney: +t.SpecifiedPrice,
							}
						}
					})
					const params = {
						id: this.activityId,
						activityName: this.ruleForm.activeName,
						begTime: this.ruleForm.StartTime,
						endTime: this.ruleForm.EndTime,
						isRest: +this.ruleForm.radioLimit == 1 ? 0 : 1, //是否限购0:限购，1：不限购
						restNum: +this.ruleForm.limitNumber,
						isOver: this.ruleForm.IsSuperpositionCoupon ? 0 : 1, //是否叠加0:叠加，1：不叠加
						activityLab: this.ruleForm.activeTag,
						listGoods: TimeLimitDiscountProductList,
						type: this.activityId ? 2 : 1, //添加传数字1，修改传数字2
					}
					const res = await timelimitdiscountedit(params);
					if (res.success) {
						this.$router.push({
							path: '/market/limitedDiscount'
						})
						this.$message({
							type: 'success',
							showClose: true,
							message: '添加成功'
						})
					} else {
						this.$message({
							type: 'error',
							showClose: true,
							message: res.alertMsg
						})
					}
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			async handleShowTooltip(val) {
				console.log("------", val)
				this.tooltipTableName = val.goodsName
				this.tooltipTableVisible = true
				try {
					this.tooltipLoading = true
					let result = await apiList.productSpecGet({
						id: val.goodsId || val.id
					})
					let data = [];
					data = result.data.list.map(t => {
						var arr = t.specValues ? JSON.parse(t.specValues) : [];
						var value1 = '';
						var value2 = '';
						arr.forEach((item, index) => {
							if (index == 0) {
								value1 = item;
							} else {
								value2 += item + ',';
							}
						})
						return {
							...t,
							ActivePrice: val.activityMoney || val.SpecifiedPrice,
							SpecValue: value1 ? value1 : '默认规格',
							SpecValue2: value2
						}
					})
					this.tooltipTableData = data;
				} catch (r) {
					console.log(r);
				} finally {
					this.tooltipLoading = false;
				}
			},
		},

	}
</script>

<style lang="less" scoped>
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.discount-container {
		.discount-item {
			display: inline-block;
		}
	}

	.discount-item {
		/deep/.el-input__inner {
			color: #909399 !important;
		}
	}

	.activepromotionMethod {
		/deep/.el-input__inner {
			color: #333 !important;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}
</style>
